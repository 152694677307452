import { Col, Container, Row } from "react-bootstrap";
import CookieConsent from "react-cookie-consent";
import { Link, useNavigate } from "react-router-dom";

const isDev = process.env.NODE_ENV === "development";

//     This website stores cookies on your computer. These cookies are used to improve your website experience and provide more personalized services to you. To find out more about the cookies we use, see our ",
const dataSection = {
  title: "",
  subtitle: "",
  description:
    "Продолжая пользоваться этим сайтом, вы соглашаетесь с использованием файлов cookie. Чтобы узнать больше о файлах cookie ознакомтесь с Учетной политикой.",
  buttons: ["Принимаю"],
};

const CookiePolicy = () => {
  const navigate = useNavigate();

  const handleShowPolicy = (e) => {
    navigate("/privacy-policy");
  };

  return (
    <CookieConsent
      debug={isDev}
      location="bottom"
      buttonText={dataSection.buttons[0]}
      ariaAcceptLabel="cookieconsent"
      disableStyles={true}
      buttonClasses="btn btn-primary btn-cookieconsent"
      containerClasses="cookieconsent cookieconsent-glass"
    >
      <Container>
        <Row>
          <Col md="12">
            <p>
              {dataSection.description}
              <Link
                className="btn btn-link link"
                aria-label="Conditions"
                onClick={handleShowPolicy}
              >
                <span>Privacy Policy</span>
              </Link>
            </p>
          </Col>
        </Row>
      </Container>
    </CookieConsent>
  );
};

export default CookiePolicy;
