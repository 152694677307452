import { Fragment, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { blogPosts } from "../../../data/dataPosts";
import { PageSection } from "../Home/HomePage";
import Pagination from "./Pagination";

const dataSection = {
  title: "Latest Blog",
  subtitle: "Latest Blog",
  description: "Latest Blog",
};

let PageSize = 3;

/**
 * Component for showing Blog Page of the site.
 *
 * @component
 */
const PostList = (props) => {
  const posts = blogPosts;

  const [currentPage, setCurrentPage] = useState(1);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return blogPosts.slice(firstPageIndex, lastPageIndex);
  }, [currentPage]);

  const PostListItem = (props) => {
    const { data, index } = props;
    console.log(data);

    const LinkToProject = (props) => {
      const { slug, otherProps } = props;
      console.log(otherProps);
      return (
        <Link to={`/blog/${slug}`} {...otherProps}>
          {props.children}
        </Link>
      );
    };

    return (
      <div className="row">
        <div className="col-md-7">
          <LinkToProject slug={data.slug}>
            <img
              className="img-fluid rounded mb-3 mb-md-0"
              src={data.image}
              alt=""
            />
          </LinkToProject>
        </div>
        <div className="col-md-5">
          <LinkToProject slug={data.slug}>
            <h3>{data.title}</h3>
          </LinkToProject>
          <p>{data.description}</p>

          <Link
            to={`/blog/${data.slug}`}
            className="btn btn-primary w-100 theme-bt"
          >
            View Project
          </Link>
        </div>
      </div>
    );
  };

  const ListPagination = () => {
    return (
      <ul className="pagination justify-content-center">
        <li className="page-item">
          <a className="page-link" href="#" aria-label="Previous">
            <span aria-hidden="true">&laquo;</span>
            <span className="sr-only">Previous</span>
          </a>
        </li>
        <li className="page-item">
          <a className="page-link" href="#">
            1
          </a>
        </li>
        <li className="page-item">
          <a className="page-link" href="#">
            2
          </a>
        </li>
        <li className="page-item">
          <a className="page-link" href="#">
            3
          </a>
        </li>
        <li className="page-item">
          <a className="page-link" href="#" aria-label="Next">
            <span aria-hidden="true">&raquo;</span>
            <span className="sr-only">Next</span>
          </a>
        </li>
      </ul>
    );
  };

  const SectionContent = () => {
    return (
      <Fragment>
        {currentTableData.map((item, index) => {
          return (
            <div key={index}>
              <PostListItem data={item} key={index} />
              <hr />
            </div>
          );
        })}
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={blogPosts.length}
          pageSize={PageSize}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </Fragment>
    );
  };

  if (!posts || posts.length === 0) {
    return <h1>No posts founds</h1>;
  }

  return (
    <PageSection id="blog-post-list" className="blog-post-list">
      <div className="container">
        {/*<SectionHeader data={dataSection}  />*/}
        <SectionContent />
      </div>
    </PageSection>
  );
};

export default PostList;
