import useAnalyticsEventTracker from "hooks/useAnalyticsEventTracker";
import { useTranslation } from "react-i18next";
import Countdown from "../../sections/TypicalSections/Countdown/Countdown";

import { Fragment, useState } from "react";
import "./CountDownPage.scss";

/**
 * Component for showing Page "Countdown" of the site.
 *
 * @component
 */
const CountDownPage = () => {
  //const location = useLocation();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const { t } = useTranslation();
  const title = t("website.countdown.title");
  const subtitle = t("website.countdown.subtitle");
  const description = t("website.countdown.description");
  const subscriptionCreated = t("website.countdown.subscriptionCreated");
  const messageSubscribe = t("website.countdown.messages.subscribe");

  const gaEventTracker = useAnalyticsEventTracker("Contact us");

  const createSubscription = async (data) => {
    const response = await fetch("http://localhost:8000/subscribe", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    return response.json();
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    console.log("Submit subscription");

    setError(null);
    try {
      gaEventTracker("submitFromCountDown");

      const response = await createSubscription({
        email: e.target.email.value,
      });

      if (response && response.error) {
        setError(response.error);
        return;
      }

      setSuccess(true);
    } catch (error) {
      setError(error.message);
      setSuccess(false);
    }
  };

  return (
    <main className="page-countdown">
      <Countdown title={title} subtitle={subtitle} description={description}>
        <Fragment>
          <form onSubmit={onSubmit}>
            <input type="email" name="email" />
            <input type="submit" value={messageSubscribe} />
          </form>
          {error && <p style={{ color: "red" }}>{error}</p>}
          {success && <p style={{ color: "green" }}>{subscriptionCreated}</p>}
        </Fragment>
      </Countdown>
    </main>
  );
};

export default CountDownPage;
