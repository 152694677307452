import { useEffect, useState } from "react";
import { Container, Nav, NavDropdown, Navbar } from "react-bootstrap";

import ReactCountryFlag from "react-country-flag";
import { useTranslation } from "react-i18next";
//import ModalContact from "../sections/Home/ModalContact/ModalContact";

// http://themazine.com/html/Lebari/lebari/index.html

const dataTopMenu = [
  { path: "/home", title: "Home", showOnlyWhenLoggedOut: true },
  { path: "/blog", title: "Blog", showOnlyWhenLoggedOut: true },
  { path: "/contact", title: "Contacts", showOnlyWhenLoggedOut: true },
  { path: "/faq", title: "F.A.Q.", showOnlyWhenLoggedOut: true },
  { path: "/login", title: "Login", showOnlyWhenLoggedOut: true },
  //{ path: '/register', title: 'Register', showOnlyWhenLoggedOut: true }
];

const dataLanguages = [
  {
    name: "Russian",
    value: "ru",
    countryCode: "RU",
  },
  {
    name: "Spanish",
    value: "es",
    countryCode: "ES",
  },
  {
    name: "English",
    value: "en",
    countryCode: "US",
  },
];

const TopMenu = () => {
  const [isSticky, setSticky] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState(null);
  const { t, i18n } = useTranslation();

  const handleScroll = () => {
    const show = window.scrollY >= 200;

    if (show) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };

  /**
   * @param {string} language
   */
  const onLanguageSelect = (language) => {
    console.log("Change lang", language);

    const currentLang = dataLanguages.find((l) => l.value === language);
    i18n.changeLanguage(language);
    setCurrentLanguage(currentLang);
  };

  const LanguageMenu = () => {
    const MenuFlag = (props) => {
      return (
        <ReactCountryFlag
          countryCode={props.countryCode}
          svg
          style={{
            width: "1em",
            height: "1em",
          }}
        />
      );
    };

    return (
      <NavDropdown
        title={
          <div style={{ display: "inline-block" }}>
            <MenuFlag
              countryCode={currentLanguage ? currentLanguage.countryCode : "RU"}
            />
          </div>
        }
        aria-labelledby="top-menu-nav-lang-dropdown"
        id="top-menu-nav-lang-dropdown"
        onSelect={onLanguageSelect}
      >
        {dataLanguages.map((language) => (
          <NavDropdown.Item
            bsPrefix="top-menu-nav-link nav-link"
            key={language.value}
            eventKey={language.value}
          >
            <MenuFlag countryCode={language.countryCode} /> {language.name}
          </NavDropdown.Item>
        ))}
      </NavDropdown>
    );
  };

  const renderMenuItems = (menuItems) => {
    return menuItems.map((menuItem) => {
      if (!menuItem.path) {
        return <></>;
      }

      return (
        <Nav.Link
          key={menuItem.path || menuItem.title}
          href={menuItem.path}
          bsPrefix="top-menu-nav-link nav-link"
        >
          {t(menuItem.title)}
        </Nav.Link>
      );
    });
  };

  useEffect(() => {
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const currentLang = dataLanguages.find((l) => l.value === i18n.language);
    //console.log("Current lang", currentLang);
    if (!currentLang) {
      i18n.changeLanguage("ru");
    }
  }, [i18n]);

  return (
    <header
      id="top-menu"
      className={
        isSticky ? "top-menu fixed-top scrolled" : "top-menu fixed-top"
      }
    >
      <Navbar variant="dark" className="top-menu-navbar" expand="md">
        <Container>
          <Navbar.Brand href="/">
            <img
              alt=""
              src={require("../../../assets/images/logo64.png")}
              width="30"
              height="30"
              className="d-inline-block align-top top-menu-navbar-icon"
            />{" "}
            FACE-OFF.CLUB
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="top-menu-navbar-nav" />
          <Navbar.Collapse
            id="top-menu-navbar-nav"
            style={{ justifyContent: "flex-end" }}
          >
            <Nav className="top-menu-navbar-nav">
              {renderMenuItems(dataTopMenu)}
              <LanguageMenu />
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default TopMenu;
