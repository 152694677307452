import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import ReactGA from "react-ga4";
import {
  Outlet,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";

import {
  CountDownPage,
  HomePage,
  NotFoundPage,
  PolicyPage,
} from "components/pages";
import ScrollToTop from "components/utils/ScrollHandler/ScrollToTop";

import "bootstrap/dist/css/bootstrap.min.css";
import { PostList, PostPage, PostsPage } from "components/pages/Blog";

import { ContactPage } from "components/pages";
import AuthPage from "components/pages/Auth/AuthPage";
import ForgotPage from "components/pages/Auth/ForgotPage";
import LoginPage2 from "components/pages/Auth/LoginPage2";
import RegisterPage from "components/pages/Auth/RegisterPage";
import FAQPage from "components/pages/FAQ/FAQPage";
import Footer from "components/sections/Footer/Footer";
import TopMenu from "components/sections/TopMenu/TopMenu";
import CookiePolicy from "components/utils/CookiePolicy/CookiePolicy";
import { useTranslation } from "react-i18next";
import "styles/css/styles.css";

const TRACKING_ID = "G-0MXDVC32PR";

ReactGA.initialize(TRACKING_ID);

const App = () => {
  //let location = useLocation();

  const RootLayout = () => {
    const { t, i18n } = useTranslation();

    return (
      <div className="layout-app">
        <ScrollToTop spy={true} smooth={true} duration={500} AOS={AOS} />

        <TopMenu />
        <div className="layout-page">
          <Outlet />
        </div>
        <Footer />
        <CookiePolicy />
      </div>
    );
  };

  useEffect(() => {
    const disableAOS = true;
    if (!disableAOS) {
      AOS.init({ duration: 2000 });
      AOS.refresh();
    }
  }, []);

  //useEffect(() => {
  // Google Analytics
  // ReactGA.pageview(location.pathname + location.search);
  //}, [location]);

  return (
    <Router>
      <Routes>
        <Route path="/countdown" element={<CountDownPage />} />
        <Route path="/login" element={<LoginPage2 />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/auth" element={<AuthPage />} />
        <Route path="/forgot-password" element={<ForgotPage />} />

        <Route path="/" element={<RootLayout />}>
          <Route index element={<HomePage />} />

          <Route path="/home" element={<HomePage />} />

          {/* <Route path="/home2" element={<HomePage2 />} />*/}

          <Route path="/privacy-policy" element={<PolicyPage />} />

          <Route path="/faq" element={<FAQPage />} />
          <Route path="/contact" element={<ContactPage />} />

          <Route path="/blog" element={<PostsPage />}>
            <Route index element={<PostList />} />
            <Route path=":slug" element={<PostPage />} />
          </Route>

          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default App;

