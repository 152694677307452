import { useEffect } from "react";
//import { animateScroll as scroll } from "react-scroll";
import { useLocation } from "react-router-dom";

const ScrollToTop = (props) => {
  const location = useLocation();
  const { AOS } = props;

  useEffect(() => {
    // delete becaouse cant tranfer history, after react-router-dom 6 upgrade
    //const unlisten = history.listen(() => {
    window.scrollTo(0, 0);
    //});
    //scroll.scrollToTop();
    AOS.refresh();
    return () => {
      //unlisten();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return <>{props.children}</>;
};

export default ScrollToTop;
