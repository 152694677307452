export const blogPosts = [
  {
    slug: "1",
    title: "Теория постановки целей Локка",
    description:
      " Многие люди попадают в ловушку, считая процесс постановки целей формальностью. В конце концов, если у вас уже есть общее представление о том, чего вы хотите достичь, не лучше ли сразу приступить к делу?",
    image: require("assets/images/blog/blog-1.jpg"),
    thumbnail: require("assets/images/blog/blog-1.jpg"),
    file: "/articles/article1.html",
    created: "01/07/2023 10:01:00",
    author: "Julia Parker",
    tags: [],
    details: [
      { title: "Lorem Ipsum" },
      {
        title: "Dolor Sit Amet",
      },
      {
        title: "Consectetur",
      },
      {
        title: "Adipiscing Elit",
      },
    ],
  },
  {
    slug: "2",
    title: "Метод постановки целей Марка Аллена",
    description:
      "Если цели пока нельзя сформулировать чётко и конкретно, используйте метод Марка Аллена. Техника подойдёт тем, кто больше ориентируется на ощущения, чем на цифры, и хочет получить план действий на долгосрочную перспективу.",
    image: require("assets/images/blog/blog-2.jpg"),
    thumbnail: require("assets/images/blog/blog-2.jpg"),
    file: "/articles/article2.html",
    created: "01/08/2023 10:01:00",
    author: "Julia Parker",
    tags: [],
    details: [
      { title: "Lorem Ipsum" },
      {
        title: "Dolor Sit Amet",
      },
      {
        title: "Consectetur",
      },
      {
        title: "Adipiscing Elit",
      },
    ],
  },
  {
    slug: "0",
    title: "Техники для достижения целей",
    description:
      "А знаете ли вы, что по статистике только 10% тех, кто ставит перед собой конкретные цели успешно их реализуют. Заявить о намерении похудеть, зарабатывать больше или начать путешествовать, маркетологам не значит стать богатым и прийти к желаемому результату. Как говорится, не бросайте достигнутые цели на полпути. Научитесь ставить их правильно. Основные техники целеполагания для разных типов людей, характеров и целей будут рассмотрены в этой и следующих статьях.",
    image: require("assets/images/blog/blog-3.jpg"),
    thumbnail: require("assets/images/blog/blog-3.jpg"),
    file: "/articles/article3.html",
    created: "01/08/2023 10:01:00",
    author: "Julia Parker",
    tags: [],
    details: [
      { title: "Lorem Ipsum" },
      {
        title: "Dolor Sit Amet",
      },
      {
        title: "Consectetur",
      },
      {
        title: "Adipiscing Elit",
      },
    ],
  },
  {
    slug: "4",
    title: "Отговорки — это ложь",
    description:
      "Отговорки — это ложь, которой мы успокаиваем себя, пытаясь избежать столкновения с неприятной правдой",
    image: require("assets/images/blog/blog-4.jpg"),
    thumbnail: require("assets/images/blog/blog-4.jpg"),
    file: "/articles/article4.html",
    created: "01/06/2023 10:01:00",
    author: "Julia Parker",
    tags: [],
    details: [
      { title: "Lorem Ipsum" },
      {
        title: "Dolor Sit Amet",
      },
      {
        title: "Consectetur",
      },
      {
        title: "Adipiscing Elit",
      },
    ],
  },
];
