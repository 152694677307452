import Banner from "components/sections/Banner/Banner";
import { Fragment } from "react";
import { dataFAQ } from "../../../data/dataFAQ";
import { FAQAccordion, PageSection, SectionHeader } from "../Home/HomePage";

const dataFAQPage = {
  title: "Frequently asked questions",
  subtitle: "Contact us to get your answer!",
  description: "Contact us to get your answer!",
};

const dataAskQuestion = {
  title: "Still have any questions?",
  subtitle: "Contact us to get your answer!",
  description: "Contact us to get your answer!",
};

/**
 * Component for showing Page "FAQ" of the site.
 *
 * @component
 */
const FAQPage = () => {
  const SectionContent = () => {
    return (
      <section id="faq" className="section page-section faq1">
        <div className="container">
          <div className="row justify-content-center">
            <div class="col-md-8 col-md-offset-2 faq-right-collapse">
              <FAQAccordion data={dataFAQ} />
            </div>
          </div>
        </div>
      </section>
    );
  };

  const SectionAskQuestion = (props) => {
    const { data } = props;

    const FormAskQuestion = () => {
      return (
        <form action="assets/php/mail.php" className="contact-form">
          <div className="row">
            <div className="col-md-6">
              <input
                type="text"
                name="name"
                id="name"
                placeholder="Имя"
                required=""
              />
            </div>
            <div className="col-md-6">
              <input
                type="email"
                name="email"
                id="email"
                placeholder="Электронная почта"
                required=""
              />
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <textarea
                name="message"
                id="message"
                placeholder="Тип сообщения"
                rows="5"
              ></textarea>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="button text-center">
                <button
                  type="submit"
                  className="btn btn-primary w-100 theme-bt"
                >
                  Отправить сообщение
                </button>
              </div>
            </div>
          </div>
        </form>
      );
    };

    return (
      <PageSection id="ask-question" className="ask-question">
        <div className="container">
          <SectionHeader data={data} />
          <div className="row justify-content-center">
            <div className="col-md-8">
              <div className="contact-form-wrapper">
                <FormAskQuestion />
              </div>
            </div>
          </div>
        </div>
      </PageSection>
    );
  };

  return (
    <Fragment>
      <main className="page-faq">
        <Banner data={dataFAQPage} />
        <SectionContent />
        <SectionAskQuestion data={dataAskQuestion} />
      </main>
    </Fragment>
  );
};

export default FAQPage;
