import { useState } from "react";
import { Col, Nav, NavItem, NavLink, Row, Tab } from "react-bootstrap";

// NOTE : https://codepen.io/JacobLett/pen/XopYBo
// NOTE : https://bootstrapmade.com/demo/Medilab/
// NOTE : https://bootstrapmade.com/demo/Medicio/
// NOTE : https://bootstrapmade.com/demo/HeroBiz/

const Section25 = (props) => {
  const { data } = props;

  const [activeTab, setActiveTab] = useState("1");
  return (
    <Tab.Container
      id="left-tabs-example"
      defaultActiveKey="first"
      activeKey={activeTab}
      animation
    >
      <Row className="clearfix">
        <Col sm={4}>
          <Nav variant="pills" stacked="true" className="d-none d-md-block">
            {data.items.map((item, index) => {
              return (
                <NavItem key={index} onClick={() => setActiveTab(item.key)}>
                  <NavLink eventKey={item.key}>{item.tabTitle}</NavLink>
                </NavItem>
              );
            })}
          </Nav>
        </Col>
        <Col sm={8}>
          <Tab.Content>
            {data.items.map((item, index) => {
              return (
                <Tab.Pane eventKey={item.key} key={index}>
                  <h2>{item.title}</h2>
                  <p>{item.subtitle}</p>
                </Tab.Pane>
              );
            })}
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  );
};

export default Section25;
