import { Col, Container, Nav, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
//import { dataOwner, dataSite } from "../../data/site";
//import { ExternalLink } from "../utils/Utils";
import { ExternalLink } from "components/utils/Utils";

import data from "data/dataSite";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const dataFooterMenu = [
  { path: "/home", title: "Home", showOnlyWhenLoggedOut: true },
  { path: "/blog", title: "Blog", showOnlyWhenLoggedOut: true },
  { path: "/contact", title: "Contacts", showOnlyWhenLoggedOut: true },
  { path: "/faq", title: "F.A.Q.", showOnlyWhenLoggedOut: true },
  //{ path: "/login", title: "Login", showOnlyWhenLoggedOut: true },
  //{ path: '/register', title: 'Register', showOnlyWhenLoggedOut: true }
];

const FooterMenu = () => {
  const [currentLanguage, setCurrentLanguage] = useState(null);
  const { t, i18n } = useTranslation();

  const renderMenuItems = (menuItems) => {
    return menuItems.map((menuItem) => {
      if (!menuItem.path) {
        return <></>;
      }

      return (
        <Nav.Link
          key={menuItem.path || menuItem.title}
          href={menuItem.path}
          bsPrefix="footer-menu-nav-link nav-link"
        >
          {t(menuItem.title)}
        </Nav.Link>
      );
    });
  };

  useEffect(() => {
    return () => {};
  }, []);

  useEffect(() => {}, [i18n]);

  return (
    <div className="footer-menu-navbar">
      <Nav className="footer-menu-navbar-nav">
        {renderMenuItems(dataFooterMenu)}
      </Nav>
    </div>
  );
};

const Contacts = () => {
  return (
    <div className="footer-top-contacts mb-4" field="descr">
      {/*<a className="" href={data.dataSite.phoneLink} rel="noopener noreferrer">
        {data.dataSite.phone}
      </a>
  <br />*/}
      <a className="" href={data.dataSite.emailLink} rel="noopener noreferrer">
        {data.dataSite.email}
      </a>
    </div>
  );
};

//  <SocialLinks />

const Footer = () => {
  const Fo1 = () => {
    return (
      <Row className="align-items-center justify-content-center">
        <Col lg="6">
          <h2 className="footer-text mb-4"> {data.subtitle}</h2>
          <p className="footer-text mb-4">{data.description}</p>

          <div className="max-w-xl lg:max-w-lg">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
              Subscribe to our newsletter.
            </h2>
            <p className="mt-4 text-lg leading-8 text-gray-300">
              Nostrud amet eu ullamco nisi aute in ad minim nostrud adipisicing
              velit quis. Duis tempor incididunt dolore.
            </p>
            <div className="mt-6 flex max-w-md gap-x-4">
              <label for="email-address" className="sr-only">
                Email address
              </label>
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required=""
                className="min-w-0 flex-auto rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                placeholder="Enter your email"
              />
              <button
                type="submit"
                className="flex-none rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
              >
                Subscribe
              </button>
            </div>
          </div>
        </Col>
        <Col lg="6">
          <Contacts />
          <FooterMenu />
        </Col>
      </Row>
    );
  };

  return (
    <footer className="main-footer">
      <Container className="footer-top">
        <Row className="align-items-center justify-content-center">
          <Col>
            <Contacts />
            <FooterMenu />
          </Col>
        </Row>
      </Container>
      <Container className="footer-bottom border-top border-smooth">
        <div className="copyright">
          © Copyright{" "}
          <strong>
            <span className="footer-text">{data.dataSite.copyright}</span>
          </strong>
          . {t("texts.All rights reserved")}.{" "}
          <Link to="/privacy-policy">{t("texts.Privacy Policy")}.</Link>
        </div>
        <div className="credits d-block">
          {t("texts.Designed")}{" "}
          <ExternalLink to="https://lovkovav.pro/" label="LovkovAV.PRO" />
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
