import { Fragment, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { Accordion } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  BiArrowBack,
  BiCapsule,
  BiCodeAlt,
  BiCodeBlock,
  BiLayer,
  BiLogoBootstrap,
  BiLogoFacebook,
  BiLogoInstagram,
  BiLogoLinkedin,
  BiLogoTelegram,
  BiLogoTwitter,
  BiRecycle,
  BiRocket,
  BiSearchAlt,
  BiSolidDashboard,
} from "react-icons/bi";

import { Section25 } from "components/sections/Section25";
import { Testimonial } from "components/sections/Testimonial";
import { formatDataShort } from "components/utils/Utils";

import { blogPosts } from "data/dataPosts";
import { dataFAQ } from "../../../data/dataFAQ";

import "./HomePage.scss";

export const PageSection = (props) => {
  const { children, className, ...otherProps } = props;

  return (
    <section
      className={
        className ? `section page-section ${className}` : `section page-section`
      }
      {...otherProps}
    >
      {children}
    </section>
  );
};

export const SectionTitle = (props) => {
  const { data, showDescription } = props;
  return (
    <Fragment>
      {data.subtitle && (
        <span
          className="wow fadeInDown"
          data-wow-delay=".2s"
          style={{
            visibility: "visible",
            animationDelay: "0.2s",
            animationName: "fadeInDown",
          }}
        >
          {data.subtitle}
        </span>
      )}
      <h2
        className="wow fadeInUp"
        data-wow-delay=".4s"
        style={{
          visibility: "visible",
          animationDelay: "0.4s",
          animationName: "fadeInUp",
        }}
      >
        {data.title}
      </h2>
      {showDescription && (
        <p
          className="wow fadeInUp"
          data-wow-delay=".6s"
          style={{
            visibility: "visible",
            animationDelay: "0.6s",
            animationName: "fadeInUp",
          }}
        >
          {data.description}
        </p>
      )}
    </Fragment>
  );
};

export const SectionHeader = (props) => {
  const { data, showDescription } = props;
  return (
    <div className="row">
      <div className="col-xl-7 col-lg-8 col-md-9 mx-auto">
        <div className="section-title text-center mb-5">
          <header>
            <SectionTitle data={data} {...props} />
          </header>
        </div>
      </div>
    </div>
  );
};

export const FAQAccordion = (props) => {
  const { data } = props;

  const AccordionItems = (props) => {
    return (
      <Fragment>
        {props.data &&
          props.data.map((item, index) => {
            return (
              <Accordion.Item eventKey={index} key={index} className="faq-item">
                <Accordion.Header>
                  <h3 className="faq-title">
                    <span className="num">{index + 1}.</span>{" "}
                    <span>{item.title}</span>
                  </h3>
                </Accordion.Header>
                <Accordion.Body className="faq-content">
                  {item.componentHTML ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.componentHTML.data,
                      }}
                    ></div>
                  ) : (
                    <p>{item.description}</p>
                  )}
                </Accordion.Body>
              </Accordion.Item>
            );
          })}
      </Fragment>
    );
  };

  return (
    <div className="faq-container">
      <Accordion defaultActiveKey="0">
        <AccordionItems data={data.items} />
      </Accordion>
    </div>
  );
};

const SectionWithHeader = (props) => {
  const { data, children, ...otherProps } = props;
  return (
    <PageSection {...otherProps}>
      <div className="container">
        <SectionHeader data={data} />
        {children}
      </div>
    </PageSection>
  );
};

/**
 * Component for showing Page "Home" of the site.
 *
 * @component
 */
const HomePage = () => {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [resentPosts, setResentPosts] = useState();

  const dataHero = {
    title: "website.hero.title",
    subtitle: "website.hero.subtitle",
    //image: require("assets/images/header-mobile.png.webp"),
    image: require("assets/images/Screen1.png"),
    imageAlt: "",
  };

  const SectionHero1 = (props) => {
    const { data } = props;
    // NOTE: https://preview.colorlib.com/#appy

    return (
      <header className="home-area overlay" id="home_page">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 hidden-sm col-md-5">
              <figure
                className="mobile-image wow fadeInUp"
                data-wow-delay="0.2s"
              >
                <img src={data.image} alt="" />
              </figure>
            </div>
            <div className="col-xs-12 col-md-7">
              <div className="my-5 hidden-xs"></div>
              <h1
                className="my-5 wow fadeInUp"
                data-wow-delay="0.4s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.4s",
                  animationName: "fadeInUp",
                }}
              >
                {t(data.title)}
              </h1>
              <div className="my-3"></div>
              <div
                className="my-3 desc wow fadeInUp"
                data-wow-delay="0.6s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.6s",
                  animationName: "fadeInUp",
                }}
              >
                <h4>{t(data.subtitle)}</h4>
              </div>
              <div className="my-2"></div>
            </div>
          </div>
        </div>
      </header>
    );
  };

  const dataSection20 = {
    title: "Как найти путь к себе?",
    subtitle: "Как найти путь к себе",
    description: "",
    items: [
      {
        title: "Понять свои желания",
        subtitle:
          "Потому что мы не знаем своих истинных желаний и своей внутренней природы",
      },
      {
        title: "Выстроить систему ценностей",
        subtitle:
          "Потому что мы рождаемся и созреваем под влиянием идей и ценностей других людей",
      },
      {
        title: "Найти свое дело",
        subtitle:
          "Потому что мы не знаем, как реализовать свои желания с пользой социуму и достойной оплатой",
      },
    ],
  };

  const SectionFeature = (props) => {
    const { data, id = "feature", className = "feature" } = props;

    const SectionContent = () => {
      return (
        <div className="row">
          <div
            className="col-md-4 aos-init aos-animate"
            data-aos="fade-up"
            data-aos-delay=""
          >
            <div className="feature-1 text-center">
              <div className="wrap-icon icon-1">
                <i className="bi bi-people"></i>
              </div>
              <h3 className="mb-3">{data.items[0].title}</h3>
              <p>{data.items[0].subtitle}</p>
            </div>
          </div>
          <div
            className="col-md-4 aos-init aos-animate"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div className="feature-1 text-center">
              <div className="wrap-icon icon-1">
                <i className="bi bi-brightness-high"></i>
              </div>
              <h3 className="mb-3">{data.items[1].title}</h3>
              <p>{data.items[1].subtitle}</p>
            </div>
          </div>
          <div
            className="col-md-4 aos-init aos-animate"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <div className="feature-1 text-center">
              <div className="wrap-icon icon-1">
                <i className="bi bi-bar-chart"></i>
              </div>
              <h3 className="mb-3">{data.items[2].title}</h3>
              <p>{data.items[2].subtitle}</p>
            </div>
          </div>
        </div>
      );
    };

    return (
      <SectionWithHeader data={data} id={id} className={className}>
        <SectionContent />
      </SectionWithHeader>
    );
  };

  const dataNewsletter = {
    title: "Join Our Newsletter",
    subtitle: "",
    description:
      "Tamen quem nulla quae legam multos aute sint culpa legam noster magna",
  };

  const SectionNewsletter = (props) => {
    const {
      data,
      id = "newsletter",
      className = "newsletter footer-newsletter",
    } = props;

    const SectionContent = () => {
      return (
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <h4>Join Our Newsletter</h4>
            <p>
              Tamen quem nulla quae legam multos aute sint culpa legam noster
              magna
            </p>
            <form action="" method="post">
              <input type="email" name="email" />
              <input type="submit" value="Subscribe" />
            </form>
          </div>
        </div>
      );
    };

    return (
      <PageSection
        id={id}
        className={className ? `${className}` : `newsletter footer-newsletter`}
      >
        <div className="container">
          <SectionContent />
        </div>
      </PageSection>
    );
  };

  // NOTE : https://www.bootdey.com/snippets/view/about-us-page-section#css

  const dataSection21 = {
    title:
      "Профессиональные и дипломированные терапевты, которым можно доверять",
    subtitle: "Профессиональные и дипломированные",
    description:
      "Подключитесь к крупнейшей в мире сети дипломированных и опытных терапевтов, которые могут помочь вам с целым рядом проблем, включая депрессию, тревогу, отношения, травмы, горе и многое другое. С нашими терапевтами вы получаете тот же профессионализм и качество, которое вы ожидаете от стационарного терапевта, но с возможностью общаться, когда и как вы хотите.",
    sections: [
      {
        section1: {
          title: "Наша миссия — сделать вашу жизнь проще",
          subtitle: "Профессиональные и дипломированные",
          description:
            "Подключитесь к крупнейшей в мире сети дипломированных и опытных терапевтов, которые могут помочь вам с целым рядом проблем, включая депрессию, тревогу, отношения, травмы, горе и многое другое. С нашими терапевтами вы получаете тот же профессионализм и качество, которое вы ожидаете от стационарного терапевта, но с возможностью общаться, когда и как вы хотите.",
        },
      },
    ],
  };

  const Section21 = (props) => {
    const { data, showHeader = false } = props;

    const SectionLeft = () => {
      return (
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-6 col-6">
            <div className="row">
              <div className="col-lg-12 col-md-12 mt-4 pt-2">
                <div className="card work-desk rounded border-0 shadow-lg overflow-hidden">
                  <img
                    src={require("../../../assets/images/Group225926.png")}
                    className="img-fluid"
                    alt="Image"
                    style={{ width: "241", height: "362" }}
                  />
                  <div className="img-overlay bg-dark"></div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-6 col-6">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="card work-desk rounded border-0 shadow-lg overflow-hidden">
                  <img
                    src={require("../../../assets/images/Rectangle86.png")}
                    className="img-fluid"
                    alt="Image"
                    style={{ width: "337", height: "450" }}
                  />
                  <div className="img-overlay bg-dark"></div>
                </div>
              </div>

              <div className="col-lg-12 col-md-12 mt-4 pt-2">
                <div className="card work-desk rounded border-0 shadow-lg overflow-hidden">
                  <img
                    src={require("../../../assets/images/Rectangle1440.png")}
                    className="img-fluid"
                    alt="Image"
                    style={{ width: "600", height: "401" }}
                  />
                  <div className="img-overlay bg-dark"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    };

    const SectionRightButtons = () => {
      return (
        <div className="row">
          <div className="col-lg-6 mt-4 pt-2">
            <div className="media align-items-center rounded shadow p-3">
              <i className="fa fa-play h4 mb-0 text-custom"></i>
              <h6 className="ml-3 mb-0">
                <a href="/home" className="text-dark">
                  Responsive
                </a>
              </h6>
            </div>
          </div>
          <div className="col-lg-6 mt-4 pt-2">
            <div className="media align-items-center rounded shadow p-3">
              <i className="fa fa-file-download h4 mb-0 text-custom"></i>
              <h6 className="ml-3 mb-0">
                <a href="/home" className="text-dark">
                  Free Download
                </a>
              </h6>
            </div>
          </div>
          <div className="col-lg-6 mt-4 pt-2">
            <div className="media align-items-center rounded shadow p-3">
              <i className="fa fa-user h4 mb-0 text-custom"></i>
              <h6 className="ml-3 mb-0">
                <a href="/home" className="text-dark">
                  Support
                </a>
              </h6>
            </div>
          </div>
          <div className="col-lg-6 mt-4 pt-2">
            <div className="media align-items-center rounded shadow p-3">
              <i className="fa fa-image h4 mb-0 text-custom"></i>
              <h6 className="ml-3 mb-0">
                <a href="/home" className="text-dark">
                  Development
                </a>
              </h6>
            </div>
          </div>
        </div>
      );
    };

    const SectionContent = () => {
      return (
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-6 order-2 order-md-1 mt-4 pt-2 mt-sm-0 opt-sm-0">
            <SectionLeft />
          </div>

          <div className="col-lg-6 col-md-6 col-12 order-1 order-md-2">
            <div className="section-title ml-lg-5">
              <h5 className="text-custom font-weight-normal mb-3">
                {data.sections[0].section1.subtitle}
              </h5>
              <h4 className="title mb-4">{data.sections[0].section1.title}</h4>
              <p className="text-muted mb-0">
                {data.sections[0].section1.description}
              </p>
            </div>
          </div>
        </div>
      );
    };

    return (
      <PageSection>
        <div className="container">
          {showHeader && <SectionHeader data={data} />}
          <SectionContent />
        </div>
      </PageSection>
    );
  };

  const dataTeam = {
    title: "Наша Команда",
    subtitle: "Команда",
    description:
      "Necessitatibus eius consequatur ex aliquid fuga eum quidem sint consectetur velit",
    items: [
      {
        index: "1",
        name: "Alexander Lovkov",
        title: "Founder, Chief Executive Officer",
        description:
          "Aliquam iure quaerat voluptatem praesentium possimus under laudantium vel dolorum distinctio dire flow",
        image: require("assets/images/team/team-2.jpg"),
        socials: [
          { type: "tg", link: "https://t.me/ddampty/" },
          { type: "ln", link: "https://www.linkedin.com/in/lovkovav/" },
          { type: "fb", link: "https://www.facebook.com/lovkovav/" },
          { type: "instagram", link: "https://www.instagram.com/ddampty/" },
        ],
        aosDelay: "100",
      },
      {
        index: "2",
        name: "Sergey Shishkov (Stalker)",
        title: "Psychologist consultant",
        description:
          "Labore ipsam sit consequatur exercitationem rerum laboriosam laudantium aut quod dolores exercitationem ut",
        image: require("assets/images/team/team-1.jpg"),
        socials: [
          { type: "fb", link: "/" },
          { type: "ln", link: "/" },
          {
            type: "instagram",
            link: "https://www.instagram.com/stalker_sergey_shishkov/",
          },
        ],
        aosDelay: "200",
      },
      {
        index: "3",
        name: "Marya Evsenkova",
        title: "CMO, Co-founder",
        description:
          "Illum minima ea autem doloremque ipsum quidem quas aspernatur modi ut praesentium vel tque sed facilis at qui",
        image: require("assets/images/team/team-3.jpg"),
        socials: [
          { type: "tg", link: "https://t.me/evsenkovapromarketing" },
          { type: "fb", link: "https://www.facebook.com/MariaEvsenkova" },
          {
            type: "ln",
            link: "https://www.linkedin.com/in/marya-evsenkova-b8b55072/",
          },
          {
            type: "instagram",
            link: "https://www.instagram.com/mara.morevna/",
          },
        ],
        aosDelay: "300",
      },
      {
        index: "4",
        name: "Vasilii Dudorov",
        title: "CIO/СТО, Co-founder",
        description:
          "Magni voluptatem accusamus assumenda cum nisi aut qui dolorem voluptate sed et veniam quasi quam consectetur",
        image: require("assets/images/team/team-4.jpg"),
        socials: [
          { type: "tg", link: "https://t.me/DudorovVasilii" },
          { type: "ln", link: "https://www.linkedin.com/in/vasilii-dudorov/" },
          { type: "fb", link: "https://www.facebook.com/vasiliy.dudorov/" },
        ],
        aosDelay: "400",
      },
      {
        index: "5",
        name: "Marina Paliv",
        title: "CSO, Sales",
        description:
          "Qui consequuntur quos accusamus magnam quo est molestiae eius laboriosam sunt doloribus quia impedit laborum velit",
        image: require("assets/images/team/team-5.jpg"),
        socials: [
          { type: "tg", link: "https://t.me/MarinaPali" },
          {
            type: "ln",
            link: "https://www.linkedin.com/in/marina-pali-44a0b721/",
          },
        ],
        aosDelay: "500",
      },
      {
        index: "6",
        name: "Evgeniy Abramov",
        title: "Front-end developer",
        description:
          "Sint sint eveniet explicabo amet consequatur nesciunt error enim rerum earum et omnis fugit eligendi cupiditate vel",
        image: require("assets/images/team/team-6.jpg"),
        socials: [
          { type: "fb", link: "/" },
          { type: "instagram", link: "/" },
        ],
        aosDelay: "600",
      },
    ],
  };

  // NOTE : https://bootstrapmade.com/demo/Shuffle/
  const SectionOurTeam = (props) => {
    const {
      data,
      id = "team",
      className = "ourteam",
      showDescription = false,
    } = props;

    const SectionContent = () => {
      // NOTE : https://bootstrapmade.com/demo/Append/

      const OurTeamSocial = ({ items }) => {
        const getIconByType = (type) => {
          switch (type) {
            case "tg":
              return <BiLogoTelegram />;
            case "tweetwter":
              return <BiLogoTwitter />;
            case "fb":
              return <BiLogoFacebook />;
            case "instagram":
              return <BiLogoInstagram />;
            case "ln":
              return <BiLogoLinkedin />;
            default:
              return null;
          }
        };

        return (
          <div className="social">
            {items.map((item, index) => {
              return (
                <a
                  href={item.link}
                  key={index}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {getIconByType(item.type)}
                </a>
              );
            })}
          </div>
        );
      };

      const OurTeamItem = ({ item }) => {
        return (
          <div
            className="col-lg-4 col-md-6 member aos-init aos-animate"
            data-aos="fade-up"
            data-aos-delay={item.aosDelay || "100"}
          >
            <div className="member-img">
              <img src={item.image} className="img-fluid" alt="" />
              <OurTeamSocial items={item.socials} />
            </div>
            <div className="member-info text-center">
              <h4>{item.name}</h4>
              <span>{item.title}</span>
              {showDescription && <p>{item.description}</p>}
            </div>
          </div>
        );
      };

      return (
        <div className="row gy-5">
          {data.items.map((item, index) => {
            return <OurTeamItem item={item} key={index} />;
          })}
        </div>
      );
    };

    return (
      <PageSection id={id} className={className}>
        <div className="container">
          <SectionHeader data={data} />
          <SectionContent />
        </div>
      </PageSection>
    );
  };

  const dataBlog = {
    title: "Блог",
    subtitle: "Последние новости",
    description: "Recent posts form our Blog",
    items: blogPosts,
  };

  const SectionBlog = (props) => {
    const {
      data,
      id = "recent-blog-posts",
      className = "recent-blog-posts",
      showDescription = false,
    } = props;

    const SectionContent = (props) => {
      const { data } = props;

      //console.log(data);

      const SectionBlogItem = (props) => {
        const { data } = props;

        return (
          <div className="post-box">
            <div className="post-img">
              <img
                src={data.thumbnail ? data.thumbnail : data.image}
                className="img-fluid"
                alt=""
              />
            </div>
            <div className="meta">
              <span className="post-date">{formatDataShort(data.created)}</span>
              <span className="post-author"> / {data.author}</span>
            </div>
            <h3 className="post-title">{data.title}</h3>
            <div className="post-content">
              <p>{data.description}</p>
            </div>
            <Link
              to={`/blog/${data.slug}`}
              className="btn btn-primary w-100 theme-bt"
            >
              <BiArrowBack />
              <span>Read More</span>
            </Link>
          </div>
        );
      };

      if (!resentPosts) return null;

      return (
        <div className="row">
          {data &&
            data.length > 0 &&
            data.map((item, index) => {
              return (
                <div
                  className="col-lg-4 aos-init aos-animate"
                  data-aos="fade-up"
                  data-aos-delay="200"
                  key={index}
                >
                  <SectionBlogItem data={item} key={index} />
                </div>
              );
            })}
        </div>
      );
    };

    return (
      <PageSection id={id} className={className}>
        <div className="container aos-init aos-animate" data-aos="fade-up">
          <SectionHeader data={data} />
          <SectionContent data={resentPosts} />
        </div>
      </PageSection>
    );
  };

  const dataProcess = {
    title: "Как это работает?",
    subtitle: "Процесс",
    description:
      "At vero eos et accusamus et iusto odio dignissimos ducimus quiblanditiis praesentium",
    items: [
      {
        title: "Зарегестрируйтесь, пройдите тестирование",
        description:
          "Проходите тестирование, определяйте свой текущий эмоциональный уровень и уровень стресса.",
        image: require("../../../assets/images/process/timeline-1.png"),
      },
      {
        title: "Ведите дневник",
        description:
          "Ведите дневник и отслеживайте изменение эмоционального уровня.",
        image: require("../../../assets/images/process/timeline-3.png"),
      },
      {
        title: "Общайтесь по-своему",
        description:
          "Поговорите со своим терапевтом так, как вам удобно: текстовое сообщение, чат, телефон или видео.",
        image: require("../../../assets/images/process/timeline-3.png"),
      },
      {
        title: "Подберите лучшего для вас терапевта",
        description:
          "Ответьте на несколько вопросов, чтобы найти дипломированного терапевта, который соответствует вашим потребностям и предпочтениям. Подключитесь к крупнейшей сети проверенных поставщиков.",
        image: require("../../../assets/images/process/timeline-2.png"),
      },
      {
        title: "Терапия, когда она вам нужна",
        description:
          "Вы можете написать своему терапевту в любое время и из любого места. Вы также можете планировать сеансы в реальном времени, когда вам удобно, и можете подключаться с любого мобильного устройства или компьютера.",
        image: require("../../../assets/images/process/timeline-4.png"),
      },
    ],
  };

  const SectionProcess = (props) => {
    const { data, id = "process", className = "process" } = props;

    const SectionContent = () => {
      return (
        <div className="row align-items-center time-line">
          {/* Item 1 */}
          <div className="col-12">
            <div className="single-timeline">
              <div className="row align-items-center">
                <div className="col-lg-5 order-last order-lg-first">
                  <div className="timeline-content left-content text-lg-right">
                    <div className="box-icon-style">
                      <BiSearchAlt />
                    </div>
                    <h4 className="mb-10">{dataProcess.items[0].title}</h4>
                    <p>{dataProcess.items[0].description}</p>
                  </div>
                </div>
                <div className="col-lg-2"></div>
                <div className="col-lg-5">
                  <div className="timeline-img">
                    <img src={dataProcess.items[0].image} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Item 1 */}
          <div className="col-12">
            <div className="single-timeline">
              <div className="row align-items-center">
                <div className="col-lg-5">
                  <div className="timeline-img">
                    <img src={dataProcess.items[1].image} alt="" />
                  </div>
                </div>
                <div className="col-lg-2"></div>
                <div className="col-lg-5">
                  <div className="timeline-content right-content text-left">
                    <div className="box-icon-style">
                      <BiLayer />
                    </div>
                    <h4 className="mb-10">{dataProcess.items[1].title}</h4>
                    <p>{dataProcess.items[1].description}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Item 1 */}
          <div className="col-12">
            <div className="single-timeline">
              <div className="row align-items-center">
                <div className="col-lg-5 order-last order-lg-first">
                  <div className="timeline-content left-content text-lg-right">
                    <div className="box-icon-style">
                      <BiCodeAlt />
                    </div>
                    <h4 className="mb-10">{dataProcess.items[2].title}</h4>
                    <p>{dataProcess.items[2].description}</p>
                  </div>
                </div>
                <div className="col-lg-2"></div>
                <div className="col-lg-5">
                  <div className="timeline-img">
                    <img src={dataProcess.items[2].image} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Item 1 */}
          <div className="col-12">
            <div className="single-timeline">
              <div className="row align-items-center">
                <div className="col-lg-5">
                  <div className="timeline-img">
                    <img src={dataProcess.items[3].image} alt="" />
                  </div>
                </div>
                <div className="col-lg-2"></div>
                <div className="col-lg-5">
                  <div className="timeline-content right-content text-left">
                    <div className="box-icon-style">
                      <BiRocket />
                    </div>
                    <h4 className="mb-10">{dataProcess.items[3].title}</h4>
                    <p>{dataProcess.items[3].description}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Item 1 */}
          <div className="col-12">
            <div className="single-timeline">
              <div className="row align-items-center">
                <div className="col-lg-5 order-last order-lg-first">
                  <div className="timeline-content left-content text-lg-right">
                    <div className="box-icon-style">
                      <BiCodeAlt />
                    </div>
                    <h4 className="mb-10">{dataProcess.items[4].title}</h4>
                    <p>{dataProcess.items[4].description}</p>
                  </div>
                </div>
                <div className="col-lg-2"></div>
                <div className="col-lg-5">
                  <div className="timeline-img">
                    <img src={dataProcess.items[4].image} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    };

    return (
      <PageSection id={id} className={className}>
        <div className="container">
          <SectionHeader data={data} />
          <SectionContent />
        </div>
      </PageSection>
    );
  };

  const dataService = {
    title: "Наши лучшие услуги",
    subtitle: "Услуги",
    description:
      "At vero eos et accusamus et iusto odio dignissimos ducimus quiblanditiis praesentium",
    items: [
      {
        title: "Освежающий дизайн",
        description:
          "Если вы много мечтаете, но не переходите к активным действиям, начинаете но быстро теряете мотивацию.  Хотите освоить навыки доведения дел до конца и достижения результата",
        icon: <BiCapsule />,
        image: null,
      },

      {
        title: "Твердый бутстрап 5",
        description:
          "Если вы страдаете от хронической или временной депрессии, ищуте способы повышения настроения, улучшения эмоционального состояния и достижения более счастливой жизни",
        icon: <BiLogoBootstrap />,
        image: null,
      },

      {
        title: " 100+ компонентов",
        description:
          "Если вам требуется персональная поддержка специалистов и бережного сообщества единомышленников для достижения своих целей и заботе о своем ментальном благополучии",
        icon: <BiCodeBlock />,
        image: null,
      },

      {
        title: "Оптимизированная скорость",
        description:
          "Если вы стремитесь к осознаности и интересуетесь майндфулнесс. Хотите жить настоящим моментом, улучшить свою концентрацию и управлять своими мыслями и эмоциями",
        icon: <BiSolidDashboard />,
        image: null,
      },

      {
        title: " Полностью настраиваемый",
        description:
          "Если вы стремятесь к саморазвитию. Хотите улучшить свою жизнь, включая личные отношения, карьеру и здоровье, хотите развить навыки лидерства, уверенности и самодисциплины",
        icon: <BiLayer />,
        image: null,
      },
      {
        title: "Регулярные обновления",
        description:
          "Если вы страдаете от стресса и тревоги. Испытываете высокий уровень стресса на работе, на учебе или в личной жизни, ищете способы управления своими эмоциями и снижения уровня тревоги",
        icon: <BiRecycle />,
        image: null,
      },
    ],
  };

  const SectionServices = (props) => {
    const { data } = props;

    const SectionContent = () => {
      return (
        <div className="row">
          {dataService.items.map((item, index) => {
            return (
              <div className="col-lg-4 col-md-6" key={index}>
                <div className="service-box box-style">
                  <div className="service-icon box-icon-style">{item.icon}</div>
                  <div className="box-content-style service-content">
                    <h4>{item.title}</h4>
                    <p>{item.description}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      );
    };

    return (
      <PageSection>
        <div className="container">
          <SectionHeader data={data} />
          <SectionContent />
        </div>
      </PageSection>
    );
  };

  const dataTestimonial = {
    title: "What Say Our Clients!",
    subtitle: "Testimonial",
    description:
      "At vero eos et accusamus et iusto odio dignissimos ducimus quiblanditiis praesentium",
    header: {
      title: "What Say Our Clients!!!",
      subtitle: "Testimonial",
      description:
        "Tempor erat elitr rebum at clita. Diam dolor diam ipsum et tempor sit. Aliqu diam amet diam et eos labore. Clita erat ipsum et lorem et sit, sed stet no labore lorem sit. Sanctus clita duo justo et tempor eirmod magna dolore erat amet",
    },
    items: [
      {
        title: "Остапов Дмитрий",
        subtitle: "Profession",
        description:
          "Одной из основных функций, которую я оценил, была возможность не только установить и отслеживать свои цели,  а еще и открыто заявлять о них в сообществе. Для меня это огромный стимул не сойти с дистанции, держать слово и доказать всем, что я способен на большее. Это невероятно мотивирующий элемент, который я больше нигде не встречал. Я очень люблю челленджи и это приложение будто создано для меня. Кроме того я также могу устанавливать сроки выполнения целей и отслеживать свой прогресс в достижении их.",
        icon: null,
        image: require("assets/images/users/user1.jpg"),
      },
      {
        title: "Ермилина Мария",
        subtitle: "Not a Profession",
        description:
          "Возможность создавать планы действий для достижения поставленных целей именно под меня, исходя из моих психологических особенностей. Очень люблю такую кастомность в приложении, позволяет ощущать индивидуальный подход к моим потребностям. Приложение предлагает шаблоны для создания планов действий и позволяет мне добавлять задачи и устанавливать приоритеты для каждой из них. Это помогает мне организовать свои действия и быть более продуктивным в достижении целей.",
        icon: null,
        image: require("assets/images/users/user2.jpg"),
      },
      {
        title: "Рузаева Юлия",
        subtitle: "Not a Profession",
        description:
          "Одной из главных преимуществ этой функции является то, что она предоставляет возможность оценить свое психоэмоциональное состояние в режиме реального времени. Я могу пройти тест и получить немедленную обратную связь о своем состоянии. Это помогает мне лучше понять свои эмоции, настроение и уровень стресса.",
        icon: null,
        image: require("assets/images/users/user4.jpg"),
      },
    ],
  };

  const SectionTestimonial = (props) => {
    const { data, id = "testimonial", className = "testimonial" } = props;

    const SectionContent = () => {
      return <Testimonial data={data} />;
    };

    return (
      <PageSection id={id} className={className}>
        <div className="container">
          <SectionHeader data={data} />
          <SectionContent />
        </div>
      </PageSection>
    );
  };

  const dataDetails1 = {
    title: "Features",
    subtitle: "Features",
    description:
      "Necessitatibus eius consequatur ex aliquid fuga eum quidem sint consectetur velit",
    items: [
      {
        key: "1",
        tabTitle: "Медитация",
        title: "Медитация",
        subtitle:
          "Хотя это может показаться парадоксальным, чем больше мы концентрируемся, тем спокойнее становится наш разум. Медитация — это техника, которая призывает направить внимание на одну точку, способствуя душевному спокойствию. Исследования показывают, что медитация улучшает психическое здоровье, облегчая такие состояния, как тревога, депрессия и стресс. Более того, регулярная практика медитации может изменить восприятие стресса и повысить эмоциональный интеллект.",
        image: null,
      },
      {
        key: "2",
        tabTitle: "Дыхание",
        title: "Дыхание",
        subtitle:
          "Дыхание — фундаментальная функция нашего тела, часто автоматическая и игнорируемая. Тем не менее, сосредоточение внимания на дыхании может стать бесценным ресурсом в управлении стрессовыми и некомфортными ситуациями. Исследования неизменно подтверждают, что дыхательные упражнения могут заметно снизить уровень тревоги и стресса. Одним из ключевых преимуществ является их немедленный эффект: уже после одного упражнения вы, вероятно, почувствуете отчетливое чувство спокойствия и расслабления. С VOS у вас есть доступ к обширной библиотеке, содержащей более 50 различных дыхательных упражнений, каждое из которых адаптировано к конкретным жизненным ситуациям, что расширяет ваш набор инструментов для психического здоровья.",
        image: null,
      },

      {
        key: "3",
        tabTitle: "Ведение журнала",
        title: "Ведение журнала",
        subtitle:
          "Ведение журнала — одна из краеугольных функций нашего приложения. Научные исследования подтверждают благотворное влияние ведения дневника на психическое здоровье — оно влияет не только на психологическое благополучие, включая настроение и работоспособность, но, что удивительно, распространяется и на физическое здоровье, влияя на такие функции, как иммунный ответ. Мы призываем наших пользователей выражать свои эмоции, точки зрения и мысли с помощью вопросов для самоанализа, тщательно составленных специалистами в области психического здоровья. Этот управляемый подход к ведению дневника помогает людям повысить осведомленность о психических процессах и эмоциях, способствуя улучшению способности к саморефлексии. Наша передовая технология искусственного интеллекта еще больше обогащает этот опыт, предлагая дополнительные вопросы, позволяя пользователям глубже углубиться в свои личные рассказы. Кроме того, наша функция открытого блокнота гарантирует, что пользователи могут вернуться к ведению журнала в любое время без каких-либо ограничений.",
        image: null,
      },
      {
        key: "4",
        tabTitle: "Звуки",
        title: "Звуки",
        subtitle:
          "Многим людям полная тишина мешает. Поэтому неудивительно, что фоновый шум существенно влияет на качество нашего сна и творческие способности. Пока исследователи продолжают исследовать, какие звуки лучше всего способствуют концентрации внимания, ваш личный опыт имеет для нас первостепенное значение. Поэтому мы предоставляем множество фоновых звуков и ритмов lo-fi, что позволяет вам выбрать именно то, что подходит вам лучше всего. В приложении VOS вы откроете для себя сотни часов разнообразных звуковых ландшафтов, охватывающих все типы, настроения и среды, созданных для создания идеальной атмосферы, соответствующей вашим потребностям.",
        image: null,
      },
      {
        key: "5",
        tabTitle: "Поиск смысла жизни",
        title: "Поиск смысла жизни",
        subtitle:
          "Логотерапия считает, что главной задачей человека является поиск смысла и цели в жизни. Разрешение внутреннего конфликта и достижение психологического благополучия возможны через осознание и осмысление собственного смысла жизни.",
        image: null,
      },
      {
        key: "6",
        tabTitle: "Актуальная направленность",
        title: "Актуальная направленность",
        subtitle:
          "Фокусировка на настоящем моменте и настоящих ценностях является ключевой идеей логотерапии. Она помогает людям сосредоточиться на своих текущих потребностях, целях и ответственностях, а также на осознании смысла и ценностей, которые могут направлять их жизнь.",
        image: null,
      },
      {
        key: "7",
        tabTitle: "Творчество и самопревосходство",
        title: "Творчество и самопревосходство",
        subtitle:
          "Логотерапия акцентирует роль творческого потенциала и стремления человека к самореализации и самопревосходству. Важным аспектом логотерапии является развитие индивидуальных талантов и использование их для достижения личной целостности и смысла жизни.",
        image: null,
      },
      {
        key: "8",
        tabTitle: "Свобода выбора",
        title: "Свобода выбора",
        subtitle:
          "Логоцентрический принцип логотерапии предполагает, что человек обладает свободой выбора и ответственностью за свои действия. Логотерапия помогает людям осознать свою свободу выбора и использовать ее для преодоления трудностей и построения смысловой жизни.",
        image: null,
      },
      {
        key: "9",
        tabTitle: "Трагедия и травма",
        title: "Трагедия и травма",
        subtitle:
          "Логотерапия придает особое значение работе с травмой и столкновением с трагическими событиями. Она помогает людям восстановить смысл и цель после потери, страданий или травматического опыта.",
        image: null,
      },
    ],
  };

  const SectionDetails1 = (props) => {
    const { data, id = "details1", className = "details1" } = props;
    return (
      <PageSection id={id} className={className}>
        <div className="container">
          <SectionHeader data={data} />
          <Section25 data={data} />
        </div>
      </PageSection>
    );
  };

  const SectionFAQ = (props) => {
    const { data, id = "faq", className = "faq1" } = props;

    const SectionContent = (props) => {
      const { data } = props;
      return (
        <div className="row gy-4">
          <div
            className="col-lg-4 aos-init aos-animate"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div className="content px-xl-5">
              <h3>
                <span>{data.title}</span>
              </h3>
              <p>{data.description}</p>
            </div>
          </div>

          <div
            className="col-lg-8 aos-init aos-animate"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <FAQAccordion data={data} />
          </div>
        </div>
      );
    };

    return (
      <PageSection id={id} className={className}>
        <div className="container">
          <SectionHeader data={data} />
          <SectionContent data={data} />
        </div>
      </PageSection>
    );
  };

  useEffect(() => {
    const fetchdata = async () => {
      const posts = blogPosts.slice(0, 3);
      //.reduce((obj, key) => {
      //  obj[key] = blogPosts[key];
      //  return obj;
      //});
      //console.log(posts);
      setResentPosts(posts);
    };

    fetchdata();
  }, []);

  return (
    <Fragment>
      <main className="page-home">
        <SectionHero1 data={dataHero} />
        <SectionFeature data={dataSection20} />
        <Section21 data={dataSection21} />
        <SectionServices data={dataService} />
        <SectionProcess data={dataProcess} />
        <SectionDetails1 data={dataDetails1} />
        <SectionTestimonial data={dataTestimonial} />
        <SectionOurTeam data={dataTeam} />
        <SectionFAQ data={dataFAQ} />
        <SectionBlog data={dataBlog} />
        <SectionNewsletter data={dataNewsletter} />
      </main>
    </Fragment>
  );
};

export default HomePage;
