import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

//import * as htmlText from "./Articles/article1.html";
import { blogPosts } from "../../../data/dataPosts";

const RelatedPosts = () => {
  return (
    <>
      <h3 className="my-4">Related Projects</h3>

      <div className="row">
        <div className="col-md-3 col-sm-6 mb-4">
          <a href="#">
            <img
              className="img-fluid"
              src="https://via.placeholder.com/500x300"
              alt=""
            />
          </a>
        </div>

        <div className="col-md-3 col-sm-6 mb-4">
          <a href="#">
            <img
              className="img-fluid"
              src="https://via.placeholder.com/500x300"
              alt=""
            />
          </a>
        </div>

        <div className="col-md-3 col-sm-6 mb-4">
          <a href="#">
            <img
              className="img-fluid"
              src="https://via.placeholder.com/500x300"
              alt=""
            />
          </a>
        </div>

        <div className="col-md-3 col-sm-6 mb-4">
          <a href="#">
            <img
              className="img-fluid"
              src="https://via.placeholder.com/500x300"
              alt=""
            />
          </a>
        </div>
      </div>
    </>
  );
};

const PostPage = () => {
  const { slug } = useParams();
  let [htmlFileString, setHtmlFileString] = useState();
  let [postData, setPostData] = useState();

  const PostDetails = (props) => {
    const { details } = props;
    console.log("2", details);
    return (
      <ul>
        {details &&
          details.map((item, index) => <li key={index}>{item.title}</li>)}
      </ul>
    );
  };

  useEffect(() => {
    const fetchdata = async () => {
      const post = blogPosts
        .filter((item) => item.slug === slug)
        .reduce((obj, key) => {
          obj[key] = blogPosts[key];
          return obj;
        });
      console.log("!!!", slug, post);
      setPostData(post);
    };
    fetchdata();
  }, []);

  useEffect(() => {
    const fetchHtml = async () => {
      setHtmlFileString(await (await fetch(postData.file)).text());
    };
    if (postData) fetchHtml();
  }, [postData]);

  if (!postData) {
    return <span>The blog post you've requested doesn't exist.</span>;
  }

  return (
    <div className="container">
      <div className="single-blog blog-style-2">
        <h1 className="my-4">{postData.title}</h1>

        <div className="row justify-content-center">
          <div className="col-md-10">
            <div className="blog-img">
              <img className="img-fluid" src={postData.image} alt="" />
            </div>
          </div>
        </div>

        <div className="blog-content">
          <div className="col-md-10">
            <p>{postData.description}</p>
          </div>

          <div dangerouslySetInnerHTML={{ __html: htmlFileString }}></div>
        </div>
      </div>
    </div>
  );
};

export default PostPage;
