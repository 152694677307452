import { Link, useLocation } from "react-router-dom";
import background from "../../../assets/common-bg.svg";

const Banner = (props) => {
  const { data } = props;
  const location = useLocation();

  let currentLink = "";
  const crumbs = location.pathname
    .split("/")
    .filter((crumb) => crumb !== "")
    .map((crumb) => {
      currentLink += `/${crumb}`;

      return (
        <li className="breadcrumb-item active" key={crumb} aria-current="page">
          <Link to={currentLink}>{crumb}</Link>
        </li>
      );
    });

  return (
    <section
      className="page-banner-section pt-75 pb-75 img-bg"
      style={{ backgroundImage: `url(${background})` }}
    >
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="banner-content">
              <h1 className="text-white">{data.title}</h1>

              <div className="page-breadcrumb">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item" aria-current="page">
                      <Link to="/">Home</Link>
                    </li>
                    {crumbs}
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
