// Ready to mograte react-router-dom 6
import { Route } from "react-router-dom";
// import { Route, useNavigate } from "react-router-dom";

//const DEBUG = process.env.REACT_APP_DEBUG;

export const cnj = (...args) => args.filter(Boolean).join(" ");

export const isOdd = (x) => {
  return x & 1;
};

export const isEven = (x) => {
  return !(x & 1);
};

export const isClient = typeof window === "object";

export const isServer = typeof window === "undefined";

export const isApiSupported = (api) =>
  typeof window !== "undefined" ? api in window : false;

export const isDevelopment =
  typeof process !== "undefined" &&
  process.env &&
  (!process.env.NODE_ENV || process.env.NODE_ENV === "development");

export const getSectionName = (courseName, name) => {
  return `${courseName}-${name}`;
};

export const groupBy = (items, key) =>
  items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []).concat(key), item],
    }),
    {}
  );

export const ConvertDateTime = (dateString) => {
  let dateFormat = new Date();
  dateFormat.setTime(Date.parse(dateString));

  return dateFormat;
};

export const formatDataShort = (date) => {
  var options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  let dateFormat = new Date();
  dateFormat.setTime(Date.parse(date));
  return dateFormat.toLocaleString("ru", options);
};

export const formatTimeDuration = (date, durationMinuts) => {
  var options = {
    hour: "2-digit",
    minute: "2-digit",
  };
  let dateStartFormat = new Date();
  dateStartFormat.setTime(Date.parse(date));
  let dateEndFormat = new Date();
  dateEndFormat.setTime(Date.parse(date) + durationMinuts * 60 * 1000);

  return `${dateStartFormat.toLocaleString(
    "ru",
    options
  )} - ${dateEndFormat.toLocaleString("ru", options)}`;
};

export const chunkArray = (chunk_array, chunk_size) => {
  var index = 0;
  const arrayLength = chunk_array.length;
  var tempArray = [];

  for (index = 0; index < arrayLength; index += chunk_size) {
    const myChunk = chunk_array.slice(index, index + chunk_size);
    tempArray.push(myChunk);
  }

  return tempArray;
};

export const Status = ({ code, children }) => (
  <Route
    render={({ staticContext }) => {
      if (staticContext) staticContext.status = code;
      return children;
    }}
  />
);

export const ExternalLink = ({ to, label, ...restProps }) => {
  return (
    <a href={to} target="_blank" rel="noreferrer" {...restProps}>
      {label}
    </a>
  );
};

export const AddExtraProps = (component, { ...extraProps }) => {
  return <component.type {...component.props} {...extraProps} />;
};
