import Banner from "components/sections/Banner/Banner";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { BiAlarm, BiMap, BiPhoneCall } from "react-icons/bi";
import background from "../../../assets/common-bg.svg";
import { PageSection, SectionHeader } from "../Home/HomePage";

const dataContactPage = {
  title: "Связаться с нами",
  subtitle: "Готовы начать работу",
  description:
    " At vero eos et accusamus et iusto odio dignissimos ducimus quiblanditiis praesentium",
};

/**
 * Component for showing Page "FAQ" of the site.
 *
 * @component
 */
const ContactPage = () => {
  const { t } = useTranslation();

  const ContactUs = () => {
    return (
      <section
        className="subscribe-section pt-70 pb-70 img-bg"
        style={{ backgroundImage: `url(${background})` }}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-6 col-lg-6">
              <div className="section-title mb-30">
                <span
                  className="text-white wow fadeInDown"
                  data-wow-delay=".2s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.2s",
                    animationName: "fadeInDown",
                  }}
                >
                  <font style="vertical-align: inherit;">Подписаться</font>
                </span>
                <h2
                  className="text-white mb-40 wow fadeInUp"
                  data-wow-delay=".4s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.4s",
                    animationName: "fadeInUp",
                  }}
                >
                  <font style="vertical-align: inherit;">
                    Подпишитесь на нашу рассылку
                  </font>
                </h2>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6">
              <form
                action="#"
                className="subscribe-form wow fadeInRight"
                data-wow-delay=".4s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.4s",
                  animationName: "fadeInRight",
                }}
              >
                <input
                  type="text"
                  name="subs-email"
                  id="subs-email"
                  placeholder="Ваш адрес электронной почты"
                />
                <button type="submit">
                  <i className="lni lni-telegram-original"></i>
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    );
  };

  const SectionContact = () => {
    const FormContact = () => {
      return (
        <form action="assets/php/mail.php" className="contact-form">
          <div className="row">
            <div className="col-md-6">
              <input
                type="text"
                name="name"
                id="name"
                placeholder={t("texts.inputUserName")}
                required=""
              />
            </div>
            <div className="col-md-6">
              <input
                type="email"
                name="email"
                id="email"
                placeholder={t("texts.inputEmail")}
                required=""
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <textarea
                name="message"
                id="message"
                placeholder={t("texts.inputMessage")}
                rows="5"
              ></textarea>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="button text-center">
                <button
                  type="submit"
                  className="btn btn-primary w-100 theme-bt"
                >
                  {t("texts.sendMessage")}
                </button>
              </div>
            </div>
          </div>
        </form>
      );
    };

    const SectionContent = () => {
      const ContactFormTitle = () => {
        return (
          <div className="section-title text-center mb-50">
            <span
              className="wow fadeInDown"
              data-wow-delay=".2s"
              style={{
                visibility: "visible",
                animationDelay: "0.2s",
                animationName: "fadeInDown",
              }}
            >
              Связаться
            </span>
            <h2
              className="wow fadeInUp"
              data-wow-delay=".4s"
              style={{
                visibility: "visible",
                animationDelay: "0.4s",
                animationName: "fadeInUp",
              }}
            >
              Готовы начать работу
            </h2>
            <p
              className="wow fadeInUp"
              data-wow-delay=".6s"
              style={{
                visibility: "visible",
                animationDelay: "0.6s",
                animationName: "fadeInUp",
              }}
            >
              At vero eos et accusamus et iusto odio dignissimos ducimus
              quiblanditiis praesentium
            </p>
          </div>
        );
      };

      return (
        <div className="container">
          <div className="row">
            <div className="col-xl-4">
              <div className="contact-item-wrapper">
                <div className="row">
                  <div className="col-12 col-md-6 col-xl-12">
                    <div className="contact-item">
                      <div className="contact-icon">
                        <BiPhoneCall />
                      </div>
                      <div className="contact-content">
                        <h4>{t("contact.contacts")}</h4>
                        <p>{t("texts.email")}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-xl-12">
                    <div className="contact-item">
                      <div className="contact-icon">
                        <BiMap />
                      </div>
                      <div className="contact-content">
                        <h4>{t("contact.address")}</h4>
                        <p>{t("texts.address")}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-xl-12">
                    <div className="contact-item">
                      <div className="contact-icon">
                        <BiAlarm />
                      </div>
                      <div className="contact-content">
                        <h4>{t("contact.WH")}</h4>
                        <p>{t("texts.WH")}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-8">
              <div className="contact-form-wrapper">
                <div className="row">
                  <div className="col-xl-10 col-lg-8 mx-auto">
                    <FormContact />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    };

    return (
      <PageSection id="contact-section" className="contact-section">
        <div className="container">
          <SectionHeader data={dataContactPage} />
          <SectionContent />
        </div>
      </PageSection>
    );
  };

  return (
    <Fragment>
      <main className="page-contact">
        <Banner data={dataContactPage} />
        <SectionContact />
      </main>
    </Fragment>
  );
};

export default ContactPage;
