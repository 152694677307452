import { dataFAQComponent1 } from "./dataFAQComponent1";

export const dataFAQ = {
  title: "Frequently Asked Questions",
  subtitle: "Часто задаваемые вопросы",
  description: "Часто задаваемые вопросы",
  items: [
    {
      title: "Что такое FACE-OFF.CLUB?",
      description:
        "FACE-OFF.CLUB — это клуб решивших изменить свою жизнь единомышлеников, не боящихся бросить вызов самому себе. Это терапевтическая платформа, полностью работающая онлайн. Мы меняем подход людей к своему психическому здоровью и помогаем им решать жизненные проблемы, предоставляя доступную и доступную помощь. С FACE-OFF.CLUB вы можете отправить сообщение профессиональному терапевту в любое время и в любом месте.",
      componentHTML: null,
      main: true,
      disable: false,
    },
    {
      title: "Сколько это стоит?",
      description:
        "Стоимость терапии через FACE-OFF.CLUB варьируется от 50 до 70 евро в неделю (счет выставляется каждые 4 недели) и зависит от вашего местоположения, предпочтений и доступности терапевта. Вы можете отменить свое членство в любое время и по любой причине.",
      componentHTML: null,
      main: true,
      disable: false,
    },
    {
      title: "Как я буду общаться со своим терапевтом?",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
      componentHTML: dataFAQComponent1,
      main: true,
      disable: false,
    },
    {
      title: "Кто мне поможет?",
      description:
        "После того, как вы зарегистрируетесь, мы подберем для вас доступного терапевта, который соответствует вашим целям, предпочтениям и типу проблем, с которыми вы сталкиваетесь. У разных терапевтов разные подходы и области деятельности, поэтому важно найти подходящего человека, который сможет добиться для вас наилучших результатов. Мы обнаружили, что в большинстве случаев можем обеспечить успешное совпадение; однако, если вы начнете процесс и почувствуете, что ваш терапевт вам не подходит, вы можете выбрать другого терапевта.",
      componentHTML: null,
      main: true,
      disable: false,
    },
  ],
};
