import { BiSolidQuoteLeft } from "react-icons/bi";
import "./Testimonial.scss";
// NOTE : https://themewagon.github.io/AI-html/

import { Carousel } from "react-bootstrap";

const dataTabs = {
  title: "Features",
  subtitle:
    " Necessitatibus eius consequatur ex aliquid fuga eum quidem sint consectetur velit",
  items: [
    {
      key: "1",
      tabTitle: "Поиск смысла жизни",
      title: "Поиск смысла жизни",
      subtitle:
        "Логотерапия считает, что главной задачей человека является поиск смысла и цели в жизни. Разрешение внутреннего конфликта и достижение психологического благополучия возможны через осознание и осмысление собственного смысла жизни.",
      image: null,
    },
    {
      key: "2",
      tabTitle: "Актуальная направленность",
      title: "Актуальная направленность",
      subtitle:
        "Фокусировка на настоящем моменте и настоящих ценностях является ключевой идеей логотерапии. Она помогает людям сосредоточиться на своих текущих потребностях, целях и ответственностях, а также на осознании смысла и ценностей, которые могут направлять их жизнь.",
      image: null,
    },
    {
      key: "3",
      tabTitle: "Творчество и самопревосходство",
      title: "Творчество и самопревосходство",
      subtitle:
        "Логотерапия акцентирует роль творческого потенциала и стремления человека к самореализации и самопревосходству. Важным аспектом логотерапии является развитие индивидуальных талантов и использование их для достижения личной целостности и смысла жизни.",
      image: null,
    },
    {
      key: "4",
      tabTitle: "Свобода выбора",
      title: "Свобода выбора",
      subtitle:
        "Логоцентрический принцип логотерапии предполагает, что человек обладает свободой выбора и ответственностью за свои действия. Логотерапия помогает людям осознать свою свободу выбора и использовать ее для преодоления трудностей и построения смысловой жизни.",
      image: null,
    },
    {
      key: "5",
      tabTitle: "Трагедия и травма",
      title: "Трагедия и травма",
      subtitle:
        "Логотерапия придает особое значение работе с травмой и столкновением с трагическими событиями. Она помогает людям восстановить смысл и цель после потери, страданий или травматического опыта.",
      image: null,
    },
  ],
};

const Testimonial = (props) => {
  const { data } = props;

  return (
    <div className="row g-5">
      <div className="col-lg-5 wow fadeIn" data-wow-delay="0.1s">
        <h2 className="mb-4">{data.header.title}</h2>
        <p className="mb-4">{data.header.description}</p>
      </div>

      <div className="col-lg-7 wow fadeIn" data-wow-delay="0.5s">
        <Carousel>
          {data.items.map((item, index) => {
            return (
              <Carousel.Item key={index}>
                <div className="testimonial-item ps-5">
                  <BiSolidQuoteLeft className="fa-2x text-primary mb-3" />
                  <div className="testimonial-content">
                    <p className="fs-4">{item.description}</p>
                  </div>
                  <div className="d-flex align-items-center mt-3">
                    <img
                      className="img-fluid flex-shrink-0 rounded-circle"
                      src={item.image}
                      style={{ width: "60px", height: "60px" }}
                      height={60}
                      width={60}
                    />
                    <div className="ps-3">
                      <h5 className="mb-1">{item.title}</h5>
                      <span>{item.subtitle}</span>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
};

export default Testimonial;
