import { useState } from "react";

//import { log } from "../../../utils/Utils";

import { useInterval } from "hooks";
import "./Countdown.scss";

const countdownDateYear = process.env.REACT_APP_COUNTDOWN_YEAR;
const countdownDateMonth = process.env.REACT_APP_COUNTDOWN_MONTH - 1; // нумерация месяцев начинается с нуля
const countdownDateDay = process.env.REACT_APP_COUNTDOWN_DAY;

const countdownDate = new Date(
  countdownDateYear,
  countdownDateMonth,
  countdownDateDay,
  0,
  0,
  0,
  0
).getTime();

//log(countdownDateYear, countdownDateMonth, countdownDateDay);
// console.log(new Date(countdownDate));

export const checkCountdownTime = () => {
  const now = new Date().getTime();
  const distance = countdownDate - now;
  return distance > 0 ? true : false;
};

const CountdownTimer = (props) => {
  const { dateTimeLocale } = props;
  const [delay] = useState(1000);
  const [isRunning] = useState(true);

  const [timerDays, setTimerDays] = useState("00");
  const [timerHours, setTimerHours] = useState("00");
  const [timerMinutes, setTimerMinutes] = useState("00");
  const [timerSeconds, setTimerSeconds] = useState("00");

  useInterval(
    () => {
      // Set Time
      const now = new Date().getTime();
      const distance = countdownDate - now;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = String(
        Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      ).padStart(2, "0");
      const minutes = String(
        Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      ).padStart(2, "0");
      const seconds = String(
        Math.floor((distance % (1000 * 60)) / 1000)
      ).padStart(2, "0");

      setTimerDays(days);
      setTimerHours(hours);
      setTimerMinutes(minutes);
      setTimerSeconds(seconds);
    },
    isRunning ? delay : null
  );

  return (
    <div className="time-countdown">
      <div id="clock" className="time-count">
        <div className="time-entry days">
          <span>{timerDays}</span>
          {dateTimeLocale[0]}
        </div>
        <div className="time-entry hours">
          <span>{timerHours}</span>
          {dateTimeLocale[1]}
        </div>
        <div className="time-entry minutes">
          <span>{timerMinutes}</span>
          {dateTimeLocale[2]}
        </div>
        <div className="time-entry seconds">
          <span>{timerSeconds}</span>
          {dateTimeLocale[3]}
        </div>
      </div>
    </div>
  );
};

export default CountdownTimer;
