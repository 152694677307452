import Banner from "components/sections/Banner/Banner";
import { Fragment } from "react";
import { Outlet } from "react-router-dom";

const dataBlogPage = {
  title: "Latest Blog",
  subtitle: "Latest Blog",
  description: "Latest Blog",
};

/**
 * Component for showing Projects Page of the site.
 *
 * @component
 */
const PostsPage = () => {
  return (
    <Fragment>
      <main className="page-blog">
        <Banner data={dataBlogPage} />
        <Outlet />
      </main>
    </Fragment>
  );
};

export default PostsPage;
