import { Link, useLocation } from "react-router-dom";

/**
 * Component for showing Page "Not Found - 404" of the site.
 *
 * @component
 */
const NotFoundPage = () => {
  const location = useLocation();
  //<PageMeta metaPage={metaPageNotFound} />
  return (
    <main className="main-page-notfound">
      <div className="notfound">
        <div className="notfound-404">
          <h1>
            4<span>0</span>4
          </h1>
        </div>
        <h2>Page not found!</h2>
        <h3>
          No match for <code>{location.pathname}</code>
        </h3>
        <p>
          The page you are looking for might have been removed had its name
          changed or is temporarily unavailable.
        </p>
        <Link to="/">home page</Link>
      </div>
    </main>
  );
};

export default NotFoundPage;
